import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import ContactFormStacked from '../ContactForm/ContactFormStacked';
import './footer.scss'

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/Terms'
    },
    {
      title: 'Privacy Policy',
      href: '/privacypage'
    }
  ]

  const serviceMenu = [
    {
      title: ' React Development',
      href: '/service/reactjs-developments'
    },
    {
      title: 'Backend Development',
      href: '/service/technology'
    },
    {
      title: 'Web Hosting',
      href: '/service/digital-marketing'
    },
    {
      title: 'Design',
      href: ' /service/ui-ux-design'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6" style={{ paddingRight: '60px' }}>
              <Div className="cs-footer_item" style={{ maxWidth: '80%' }}>
                <TextWidget
                  logoSrc='/images/logo.png'
                  logoAlt='Logo'
                  text="Femto focuses on bringing your digital vision to life by leveraging modern frameworks and custom coding solutions."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services' />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us' withIcon={true} />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactFormStacked
                  subtitle='Leave your details here and one of our representatives will contact you shortly.'
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
      
        <Div className="cs-bottom_footer">
        <MenuWidget menuItems={copyrightLinks} menuHeading='' />
        </Div>
      </Div>
    </footer>
  )
}