import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'

import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import IconBox from '../IconBox'
export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading
        title='Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Services'
      />
      <Spacing lg='150' md='80' />
      <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Services we can help you with'
                subtitle='What Can We Do'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Backend Development'
                    link='/service/backend-development'
                    src='/images/backend.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Design'
                    link='/service/ui-ux-design'
                    src='/images/service_1.jpeg'
                    alt='Service'
                  />

                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='React.js Development'
                    link='/service/reactjs-development'
                    src='/images/service_2.jpeg'
                    alt='Service'
                  />

                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>

                  <Card
                    title='Web Hosting'
                    link='/service/web-hosting'
                    src='/images/web-hosting.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                {/*   <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Consultancy'
                    link='/service/consultancy'
                    src='/images/service_5.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Creative Design'
                    link='/service/creative-design'
                    src='/images/service_6.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>*/}

              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />

      <Spacing lg='125' md='55' />
      <Div className="container">
        <SectionHeading 
          title='Our Working Process' 
          subtitle='From Vision To Reality'
          variant='cs-style1 text-center'
        />
        <Spacing lg='125' md='55' />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.png'
              title='Sketching'
              subtitle='The first stage is to sketch the design and functionality of the project using paper or digital tools. This involves creating a rough layout of the project and its features, including the navigation, content/data structure, and visual elements and other relevent aspects.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.png'
              title='Staging'
              subtitle="In this stage, developers set up the development environment and start building the project using the appropriate frameworks. They create the main components of the project and integrate them into the design. This involves coding the functionality and interactions of the project."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.png'
              title='Production'
              subtitle="Once the project is completed, it is deployed to the production server. This stage involves testing the website to ensure it is working properly, fixing any bugs, and optimizing it for speed and performance. The website is then made live for users to access."
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <br></br>
      <h4 style={{textAlign: 'center'}}>Overall, these stages can help streamline the development process<br></br> and ensure that the final product meets the desired requirements and goals.</h4>
      {/* <Spacing lg='125' md='55' />
      <TestimonialSlider /> */}
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title="Let’s talk and make <br/> something <i> amazing </i> together"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  )
}
