import React from 'react'
import { useState } from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'

export default function PricingTableList() {
  const [tab, setTab] = useState('monthly')
  const [currency,setCurrency] = useState('GBP');
  return (
    <Section className="position-relative">
      <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
        <li className={tab === 'monthly' ? "active" : ""} onClick={()=>setTab('monthly')}>Monthly</li>
        <li className={tab === 'yearly' ? "active" : ""} onClick={()=>setTab('yearly')}>Yearly</li>
      </ul>
      <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font" style={{marginRight:'250px'}}>
        <li className={currency === 'GBP' ? "active" : ""} onClick={()=>setCurrency('GBP')}>£ GBP </li>
        <li className={currency === 'USD' ? "active" : ""} onClick={()=>setCurrency('USD')}>$ USD</li>
      </ul>
      <Section className="row">
        <Section className="col-lg-4">
          {tab==='monthly' && (
            <PricingTable 
              title='Standard'
              price={ currency === 'GBP'?40:49}
              currency={ currency === 'GBP'?'£':'$'}
              timeline='monthly'
              features={['1 Website', 'Free Domain - 1 Year', '5GB SSD Storage', 'Free CDN Setup', 'Free SSL Certificate', 'Free Google Analytics Setup']}
              btnText='Contact Us'
              btnLink='/contact'
            />
          )}
          {tab==='yearly' && (
            <PricingTable 
              title='Standard'
              price={ currency === 'GBP'?400:490}
              currency={ currency === 'GBP'?'£':'$'}
              timeline='yearly'
              features={['1 Website', 'Free Domain - 1 Year', '5GB SSD Storage', 'Free CDN Setup', 'Free SSL Certificate', 'Free Google Analytics Setup']}
              btnText='Contact Us'
              btnLink='/contact'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
         {tab==='monthly' && (
            <PricingTable 
              title='Professional'
              price={ currency === 'GBP'?100:121}
              currency={ currency === 'GBP'?'£':'$'}
              timeline='monthly'
              features={['5 Websites', 'Free 3 Domain - 1 year', '30GB SSD Storage', 'Free CDN Setup', 'Free SSL Certificate', 'Free Google Analytics Setup','24/7 Customer Support','Free Automatic Backup']}
              btnText='Contact Us'
              btnLink='/contact'
            />
          )}
          {tab==='yearly' && (
            <PricingTable 
            title='Professional'
              price={ currency === 'GBP'?900:1089}
              currency={ currency === 'GBP'?'£':'$'}
              timeline='yearly'
              features={['Unlimited Websites', ' 5  Free Domains - 1 year', '30GB SSD Storage','Free CDN Setup', 'Free SSL Certificate', 'Free Google Analytics Setup','24/7 Customer Support','Free Automatic Backup']}
              btnText='Contact Us'
              btnLink='/contact'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
          {tab==='monthly' && (
            <PricingTable 
              title='Ultimate'
              price={ currency === 'GBP'?210:255}
              currency={ currency === 'GBP'?'£':'$'}
              timeline='monthly'
              features={['Unlimited Websites', ' Up To 10 Free Domains','100GB SSD Storage', 'Free CDN Setup', 'Free SSL Certificate', 'Free Google Analytics Setup','24/7 Customer Support','Free Automatic Backup']}
              btnText='Contact Us'
              btnLink='/contact'
            />
          )}
          {tab==='yearly' && (
            <PricingTable 
              title='Ultimate'
              price={ currency === 'GBP'?1200:1580}
              currency={ currency === 'GBP'?'£':'$'}
              timeline='yearly'
              features={['Unlimited Websites', ' Up To 20 Free Domains', '100GB SSD Storage','Free CDN Setup', 'Free SSL Certificate', 'Free Google Analytics Setup','24/7 Customer Support','Free Automatic Backup']}
              btnText='Contact Us'
              btnLink='/contact'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
      </Section>
    </Section>
  )
}
