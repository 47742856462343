import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
//import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
//import FunFactWithContact from '../FunFact/FanFactWithContact'
export default function PrivacyPolicyPage() {
    pageTitle('About');


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* Start Page Heading Section */}
            <PageHeading
                title="Privacy Policy"
                bgSrc="images/about_hero_bg.jpeg"
                pageLinkText="Privacy Policy"
            />
            {/* End Page Heading Section */}

            {/* Start About Section */}
            <Spacing lg="150" md="80" />
            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-12 col-lg-7">
                        <SectionHeading
                            title="Privacy Policy"
                            subtitle="  "
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">

                                Privacy Policy
                                <br></br><br></br>
                                This Privacy Policy applies to the website femto.co.il which is owned and operated by Femto, a custom web development service provider (“Femto”, “we”, “us”, “our”). We are committed to protecting the privacy of our clients and website visitors and have created this policy to outline the types of information we collect and how that information is used and protected.
                                <br></br><br></br>
                                Information We Collect
                                <br></br><br></br>
                                We collect information from our clients and website visitors in several ways, including:
                                <br></br><br></br>
                                1. Information provided voluntarily: This includes information provided when clients request a quote or sign up for our services, such as name, email address, phone number, and company information.
                                <br></br><br></br>
                                2. Information collected automatically: This includes information collected through the use of cookies, which helps us to improve our website and services.
                                <br></br><br></br>
                                Use of Information
                                <br></br><br></br>
                                We use the information collected for the following purposes:
                                1.To provide our services: We use client information to provide custom web development services and to communicate with clients regarding their projects
                                <br></br>
                                2.To improve our website and services: We use information collected automatically to improve our website and services and to provide a better user experience.
                                <br></br>
                                3.To communicate with clients: We may use client information to communicate with them regarding their projects, to provide updates on our services, or to respond to inquiries.
                                <br></br>
                                Data Security
                                <br></br><br></br>
                                We take the security of client information seriously and have implemented appropriate technical and organizational measures to protect against unauthorized access, alteration, disclosure, or destruction of personal data.
                                <br></br><br></br>
                                Data Retention
                                <br></br><br></br>
                                We retain client information for as long as necessary to provide our services and for a reasonable time thereafter in accordance with applicable laws
                                <br></br><br></br>
                                Third-Party Sharing
                                <br></br><br></br>
                                We do not sell, rent, or share client information with third parties, except as required by law or as necessary to provide our services.
                                <br></br><br></br>
                                Your Rights
                                <br></br><br></br>
                                Clients have the right to access, correct, and request the deletion of their personal data. If you would like to exercise these rights, please contact us at info@femto.co.il.
                                <br></br><br></br>
                                Changes to this Privacy Policy
                                <br></br><br></br>
                                We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. Any changes will be posted on this page and will become effective upon posting.
                                <br></br><br></br>
                                Contact Us
                                <br></br><br></br>
                                If you have any questions or concerns about this Privacy Policy, please contact us at info@femto.co.il.
                                <br></br><br></br>
                                Effective Date: 13/02/2023.

                            </p>
                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">

                        <Spacing lg="25" md="25" />
                    </Div>
                    <Div className="col-lg-7">

                        <Spacing lg="25" md="25" />
                    </Div>
                    <Div className="col-lg-5">

                        <Spacing lg="25" md="25" />
                    </Div>
                </Div>
            </Div>
            <Spacing lg="75" md="55" />
            {/* End About Section */}

            {/* End CTA Section */}
        </>
    );
}