import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
//import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
//import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import FunFactWithContact from '../FunFact/FanFactWithContact'
export default function AboutPage() {
  pageTitle('About');

  const funfaceData = [
    {
      title: 'Global Happy Clients',
      factNumber: '40K',
    },
    {
      title: 'Project Completed',
      factNumber: '50K',
    },
    {
      title: 'Team Members',
      factNumber: '245',
    },
    {
      title: 'Digital products',
      factNumber: '550',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="From Vision to Reality"
              subtitle="About Our Company"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                At Femto, we specialize in turning your digital aspirations into reality. Our team of skilled developers utilizes the latest frameworks to create seamless, high-end web products that align with your company's goals and values.
                <br></br><br></br>
                Committed to excellence in all aspects of web development, we begin our process with a thorough consultation to gain a comprehensive understanding of your company's objectives and the desired functionality of your online presence. Our dedicated team of experts then tailors our diverse toolkit to your unique requirements, while our attentive client managers keep you updated on the progress every step of the way.
                <br></br><br></br>
                Don't hesitate to reach out and discover how we can collaborate to transform your vision into a captivating digital experience. Contact us today, and let's start building the future of your online presence together!
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFactWithContact
          title="Our fun fact"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Femto: Your Partner In Custom Web Development"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Selecting Femto as your custom web development partner comes with numerous advantages. Our seasoned team of experts boasts extensive expertise in employing state-of-the-art frameworks and tailored coding solutions to materialize your digital vision.

              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">

                We recognize the significance of crafting a seamless, high-end product that genuinely embodies your company's objectives and values. To ensure a smooth collaboration, our client managers maintain transparent communication and keep you apprised of every step in the development process.

              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">

                Our dedication to customizing our tools and strategies to cater to your unique needs guarantees that your vision will be realized with precision and excellence. By choosing to work with Femto, you gain access to a dependable and proficient team committed to transforming your web development concepts into reality.

              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s talk and make <br/> something <i> amazing </i> together"
          btnText="Contact Us"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
