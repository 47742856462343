import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Spacing from '../Spacing'
import SocialWidget from '../Widget/SocialWidget'
import { useLocation } from 'react-router-dom';
export default function TeamDetails() {
  const location = useLocation();
  const [Name, setName] = useState(null);
  const [position, setPosition] = useState(null);
  const [image, setImage] = useState(null);
  pageTitle('Team Member');
  useEffect(() => {
    setName(location.state.Name)
    setPosition(location.state.title)
    setImage(location.state.image)
    window.scrollTo(0, 0)
  }, [Name, position, image])
  return (
    <>
      <PageHeading
        title='Team Details'
        bgSrc='/images/team_hero_bg.jpeg'
        pageLinkText='Team Details'
      />
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src={image} alt={Name} className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title">{Name}</h2>
              <Div className="cs-height_10 cs-height_lg_10" />
              <h3 className="cs-section_subtitle">{position}</h3>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              {Name === 'Matan Rotgolz' ?
                <>
                  <p className="cs-m0">Matan is a Full-Stack Developer with a focus on frontend development.</p>
                  <Div className="cs-height_25 cs-height_lg_20" />
                  <p className="cs-m0">With over five years of programming experience, Matan began his career at 16 as a cadet at the IAF academy in Haifa. Upon graduation from high school, Matan studied electronics at Ort College with a specialization in computer communication and computer science.</p>
                  <Div className="cs-height_45 cs-height_lg_30" />
                  <p className="cs-m0">
                  In 2019, Matan joined the IAF as a network technician. After six months, he was promoted to team leader of IT & network technicians. While in service, he also learned the secrets of web development and became a full-stack engineer.
                  </p>
                  <Div className="cs-height_25 cs-height_lg_20" />
                  <p className="cs-m0">The legend of Femto began in 2022 when Matan and co-founders Omer & Chen founded the company..</p>
                  <Div className="cs-height_25 cs-height_lg_20" />
                  <p className="cs-m0">
                  Matan’s experience and wide range of knowledge in various tech areas enable him to provide solutions and ultimately help you realize your vision.

                    </p>
                  <Div className="cs-height_25 cs-height_lg_20" />
                </> : Name === 'Chen Baran' ?
                  <>
                    <p className="cs-m0">Chen is a Full-Stack Web Developer with a focus on backend development.</p>
                    <Div className="cs-height_25 cs-height_lg_20" />
                    <p className="cs-m0">
                    In addition to being a Full-Stack Web Developer, Chen has over five years of experience marketing, designing and building a wide variety of websites (such as showcase websites, e-commerce websites, promotional landing pages and more) for businesses and organizations. To ensure a high quality finished product, Chen places a high emphasis on design and functionality - paying attention to the small details to arrive at a high-end finished product.
                    </p>
                    <Div className="cs-height_45 cs-height_lg_30" />
                    <p className="cs-m0">
                    After working over five years in a small web design agency, Chen has decided, along with co-founders Omer and Matan, to open Femto - supplying a higher quality service with modern, robust frameworks that allow for more flexible capabilities and more complex web development projects.


                    </p>
                    <Div className="cs-height_25 cs-height_lg_20" />
                    <p className="cs-m0">Chen’s previous experience with digital marketing, advertising and design allows him to combine his full-stack knowledge to build practical user interfaces and user experiences that align with your strategic goals and targets.
                    </p>
                    <Div className="cs-height_25 cs-height_lg_20" />
                    <p className="cs-m0">With a focus on backend programming, Chen has the ability to manage servers, databases, and build custom APIs in order to create website functionality tailored exactly to the needs of your project. 

                    </p>
                    <Div className="cs-height_25 cs-height_lg_20" />
                  </> : ''
              }

              <SocialWidget />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Let’s disscuse make <br />something <i>cool</i> together'
          btnText='Apply For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
