import React, { useState } from 'react';
import Div from '../Div';
import './ContactForm.scss';
import { Icon } from '@iconify/react';
import SendDetails from '../../code/mailsent.js';

export default function ContactFormStacked({ subtitle }) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    SendDetails(fullName, email, mobile)
      .then(response => {
        setStatus('Your details have been sent successfully and one of our representatives will contact you shortly.');
        setFullName('');
        setEmail('');
        setMobile('');
      })
      .catch(error => {
        console.error(error);
        setStatus('Error occurred while sending the form data.');
      });
  };

  return (
    <Div className="cs-contactformstacked_right">
      <form onSubmit={handleSubmit}>
        <Div className="cs-contactformstackeds">
          <Div className="form-group" style={{ display: 'flex', flexDirection: 'row' }}>
            <Div className="col-md-12" style={{ marginRight: '20px' }}>
              <input type="text" className="cs-form_field" placeholder="Full Name*" required value={fullName} onChange={(e) => setFullName(e.target.value)} />
            </Div>
            <Div className="col-md-12">
              <input type="tel" className="cs-form_field" placeholder="Mobile*" required value={mobile} onChange={(e) => setMobile(e.target.value)} />
            </Div>
          </Div>
        </Div>
        <Div className="col-md" style={{ marginTop: '20px' }}>
          <input type="email" className="cs-form_field" placeholder="Email*" required value={email} onChange={(e) => setEmail(e.target.value)} />
        </Div>
        <Div className="col-sm-12" style={{ marginTop: "20px" }} >
          <button className="cs-btn cs-style1">
            <span>Send</span>
            <Icon icon="bi:arrow-right" />
          </button>
        </Div>
        {status && <Div className="cs-contactformstacked_text contact-stacked-status" style={{ marginTop: "15px" }}>{status}</Div>}
      </form>
      <Div className="cs-contactformstacked_text" style={{ marginTop: "15px" }}>{subtitle}</Div>
    </Div>
  )
}
