const SendDetails = (fullName, emailAddress, mobileNumber, projectType, message) => {
  const data = {
    Full_Name: fullName !== undefined && fullName !== null ? fullName : '',
    Email: emailAddress !== undefined && emailAddress !== null ? emailAddress : '',
    Project_Type: projectType !== undefined && projectType !== null ? projectType : '',
    Mobile: mobileNumber !== undefined && mobileNumber !== null ? mobileNumber : '',
    Message: message !== undefined && message !== null ? message : '',
  };

  return fetch('https://sheetdb.io/api/v1/r85ohxxoje4nv', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .catch(error => {
      console.error(error);
      throw new Error('Error occurred while sending the form data.');
    });
};

export default SendDetails;
