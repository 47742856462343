import React, { useEffect, useState } from 'react';
import Div from '../Div';
import './funfact.scss';
import './contact.scss';
import { Icon } from '@iconify/react';
import SendDetails from '../../code/mailsent.js';

export default function FunFactWithContact({ variant, title, subtitle, data }) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    SendDetails(fullName, email, mobile)
      .then(response => {
        setStatus('Your details have been sent successfully and one of our representatives will contact you shortly.');
        setFullName('');
        setEmail('');
        setMobile('');
      })
      .catch(error => {
        console.error(error);
        setStatus('Error occurred while sending the form data.');
      });
  };

  useEffect(() => {

  }, [fullName, email, mobile, status]);

  return (
    <Div className={variant ? `cs-funfact_wrap ${variant}` : 'cs-funfact_wrap'}>
      <Div className="cs-funfact_shape" style={{ backgroundImage: 'url(./images/funfact_shape_bg.svg)' }} />
      <Div className="cs-funfact_left">
        <Div className="cs-funfact_heading">
          <h2>Get a Quote</h2>
          <p>Leave your contact details and one of our representatives will get in touch to talk about your project.</p>
        </Div>
      </Div>
      <Div className="cs-funfact_right">
        <Div className="cs-funfacts">
          <form onSubmit={handleSubmit}>
            <Div className="form-group">
              <Div className="col-sm-5 homepage-full-name">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" className="cs-form_field" required value={fullName} onChange={(e) => setFullName(e.target.value)} />
              </Div>
              <Div className="col-sm-5 homepage-email">
                <label className="cs-primary_color">Email*</label>
                <input type="email" className="cs-form_field" required value={email} onChange={(e) => setEmail(e.target.value)} />
              </Div>
              <Div className="col-sm-5 homepage-mobile">
                <label className="cs-primary_color">Mobile*</label>
                <input type="tel" className="cs-form_field" required value={mobile} onChange={(e) => setMobile(e.target.value)} />
              </Div>
              <Div className="col-sm-12 homepage-submit">
                <button className="cs-btn cs-style1"
                 id="fun-fact-with-contact-button"
                 >
                  <span>Send</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </Div>
          </form>
          {status && <Div className="homepage-status">{status}</Div>}
        </Div>
      </Div>
    </Div>
  );
}
